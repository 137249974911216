<template>
   <DropdownSelector
        v-bind="$attrs"
        :selection="selection"
        :name="name"
        searchValue="value"
        itemValue="value"
        itemText="value"
        :setTheme="setTheme"
    >
        <template #additional-controls>
            <div class="flex gap-1.5 text-xxs flex-wrap px-4 pt-4 pb-2">
                <region-button 
                    v-for="region in regions" 
                    :region="region"
                    :selection="selection"
                    :regionNames="regionMapNames[region]"
                    :setTheme="setTheme"
                    :key="region" 
                    @selectRegion="selectRegion"
                    @deselectRegion="deselectRegion"
                />
            </div>
        </template>
    </DropdownSelector>
</template>
<script>
import DropdownSelector from "./DropdownSelector";
import RegionButton from "./RegionButton";
export default {
    components: {
        DropdownSelector,
        RegionButton,
    },
    
    data() {
        return {
        };
    },
    
    props: {
        selection: {
            default: [],
        },
        setTheme: {
            type: String,
            default: 'default',
        },
        name: {
            type: String,
            default: 'STATES',
        },
    },

    computed: {
        regions(){
            return Object.keys(this.regionMapNames).sort();
        },

        regionMapNames(){
            return this.$store.getters["region/regionMapNames"];
        },

        computedClasses(){
            if(this.setTheme === "dark"){
                return "hover:bg-blueGray-900 bg-blueGray-900/60 text-accentBlue";
            }

            return "hover:bg-secondary-100 bg-gray-100";
        },
    },

    methods: {
        selectRegion(region){
            /* 
            * If we want to add this region to our current selections use this version instead 
            */
            let selection = new Set([...this.selection, ...this.regionMapNames[region]]);
            this.$emit("onSelectionChange", [...selection]);

            /*
            * If we want to replace our current selections with this region use this version instead
            this.$emit("onSelectionChange", this.regionMapNames[region]);
            */
        },

        deselectRegion(region){
            let regionNames = this.regionMapNames[region];
            let newSelection = this.selection.filter(name => !regionNames.includes(name));
            this.$emit("onSelectionChange", newSelection);
        },
    },
};
</script>
<style scoped>
</style>
