import dateService from "@/services/utils/dateFormatterService";
import startCase from "lodash/startCase";
import pick from "lodash/pick";

export default {
    namespaced: true,

    state() {
        return {};
    },

    mutations: {},

    actions: {},

    getters: {
        /*
         * Receives an array of sections and only returns the sections in the specified array.
         * As a bonus advantage, the properties in the returned objects have the same order
         * as the selected_sections array
         */
        getSections: (_state, getters) => (selected_sections) => {
            const filtered = pick(getters.sections, selected_sections);
            let sectionsArr = Object.values(filtered);
            if (selected_sections.includes('insuranceSections')) {
                sectionsArr = [...sectionsArr, ...getters.insuranceSections]
            }
            return sectionsArr

        }, 
        sections(_state, getters) {
            return {
                aboutSection: getters.aboutSection,
                residenceSection: getters.residenceSection,
                residenceWithPhoneSection: getters.residenceWithPhoneSection,
                deliverySection: getters.deliverySection,
                diagnosisSection: getters.diagnosisSection,
                equipmentSection: getters.equipmentSection,
                mdmSection: getters.mdmSection,
                additionalMdmSection: getters.additionalMdmSection,
                slpSection: getters.slpSection,
                physicianSection: getters.physicianSection,
                equipmentSelectionSection: getters.equipmentSelectionSection,
                trialEquipmentSelectionSection: getters.trialEquipmentSelectionSection,
                trialEquipmentSelectionV2Section: getters.trialEquipmentSelectionV2Section,
                qadSection: getters.qadSection
            };
        },



        /*
         * Helpful Computed properties
         */
        application(_state, _getters, rootState) {
            return rootState.applications.current;
        },
        basicInfo(_state, getters) {
            return getters.application.client_info?.basic_info ?? {};
        },
        clientDob(_state, getters) {
            return dateService.humanReadable(getters.basicInfo.dob);
        },

        residentAddress(_state, getters) {
            return getters.basicInfo?.address ?? {};
        },
        delivery(_state, getters) {
            return getters.application.client_info?.delivery ?? {};
        },

        deliveryAddress(_state, getters) {
            return getters.application.client_info?.delivery.address ?? {};
        },
        medicalDate(_state, getters) {
            return dateService.humanReadable(
                getters.basicInfo.medical_diagnosis_date
            );
        },

        speechDate(_state, getters) {
            return dateService.humanReadable(
                getters.basicInfo.speech_diagnosis_date
            );
        },

        additionalMedicalDate(_state, getters) {
            let value = dateService.humanReadable(
                getters.basicInfo.additional_diagnosis_date
            );

            if (value === null || value === undefined) {
                value = "--";
            }
            return value;
        },

        additionalSpeechDate(_state, getters) {
            let value = dateService.humanReadable(
                getters.basicInfo.additional_diagnosis_2_date
            );
            if (value === null || value === undefined) {
                value = "--";
            }
            return value;
        },

        accidentDate(_state, getters) {
            return dateService.humanReadable(getters.basicInfo.date_of_accident);
        },
        purchaseDate(_state, getters) {
            return dateService.humanReadable(getters.basicInfo.sgd_purchase_date);
        },

        mountDate(_state, getters) {
            return dateService.humanReadable(
                getters.basicInfo.mount_purchase_date
            );
        },
        advocate(_state, getters) {
            return getters.application.client_info?.advocate ?? {};
        },
        advocateAddress(_state, getters) {
            return getters.application.client_info?.advocate?.address ?? {};
        },
        advocateAdditionalAddress(_state, getters) {
            return getters.application.client_info?.advocate?.additional_address ?? {};
        },

        slp(_state, getters) {
            return getters.application.client_info?.slp ?? {};
        },

        slpAddress(_state, getters) {
            return getters.application.client_info?.slp?.address ?? {};
        },

        physician(_state, getters) {
            return getters.application.client_info?.physician ?? {};
        },

        physicianAddress(_state, getters) {
            return getters.physician.address ?? {};
        },
        insurances(_state, getters) {
            return getters.application.insurance_info?.insurance_data ?? [];
        },
        equipmentSelection(_state, getters) {
            return getters.application.equipment_selection_info ?? {};
        },

        device(_state, getters) {
            return getters.application.device ?? {};
        },
        selectedDevice(_state, getters) {
            return getters.application?.selected_device
        },
        trialEquipment(_state, getters) {
            return getters.application.trial_equipment_selection_info ?? {};
        },
        trialEquipmentv2(_state, getters) {
            return getters.application.trial_equipment_selection_v2_info ?? {};
        },
        systemIntegration(_state, getters) {
            return getters.application.system_integration ?? {};
        },
        soNumbers(_state, getters) {
            let numbers = getters.systemIntegration.so_numbers ?? [];  
            return numbers.map(number => number.number).join(", ");  
        },
        rmaNumbers(_state, getters) {
            let numbers = getters.systemIntegration.rma_numbers ?? [];
            return numbers.map(number => number.number).join(", "); 
        },

        /*
         * Section definitions
         */
        aboutSection(_state, getters) {
            return {
                name: "aboutSection",
                title: "About the Client",
                route: "application.clientInfo",
                items: [
                    {
                        name: "first_name",
                        value: getters.basicInfo.first_name,
                    },
                    {
                        name: "middle_name",
                        value: getters.basicInfo.middle_name,
                        hideOnNull: true
                    },
                    {
                        name: "last_name",
                        value: getters.basicInfo.last_name,
                    },
                    {
                        name: "dob",
                        text: "Date of Birth",
                        value: getters.clientDob,
                    },
                    {
                        name: "gender",
                        value: getters.basicInfo.gender,
                    },
                    {
                        name: "email",
                        value: getters.basicInfo.email,
                        keep_underscores: true
                    },
                    {
                        name: "phone",
                        value: getters.basicInfo.phone,
                        canText: getters.basicInfo.accept_texts,
                    },
                    {
                        name: "alt_phone",
                        label: "Phone 2",
                        value: getters.basicInfo.alt_phone,
                        canText: getters.basicInfo.alt_accept_texts,
                    },
                    {
                        name: "preferred_language",
                        value: getters.basicInfo.preferred_language ?? "English",
                    },
                ],
            };
        },
        residenceSection(_state, getters) {
            return {
                name: "residenceSection",
                title: "Client Residence",
                route: "application.clientInfo.residence",
                address: getters.residentAddress,
                items: [
                    {
                        name: "place_of_residence",
                        value: getters.basicInfo.place_of_residence,
                    },
                    {
                        name: "place_of_residence_other",
                        label: "Other Residence",
                        value: getters.basicInfo.place_of_residence_other,
                    },
                    {
                        name: "facility",
                        value: getters.residentAddress.facility,
                    },
                    {
                        name: "address_1",
                        label: "Address",
                        value: getters.residentAddress.address_1,
                    },
                    {
                        name: "address_2",
                        label: false,
                        value: getters.residentAddress.address_2,
                    },
                    {
                        name: "city",
                        value: getters.residentAddress.city,
                    },
                    {
                        name: "state",
                        value: getters.residentAddress.state ?? "",
                    },
                    {
                        name: "zip",
                        value: getters.residentAddress.zip,
                    },
                    {
                        name: "county",
                        value: getters.residentAddress.county,
                    },
                ],
            };
        },
        residenceWithPhoneSection(_state, getters) {
            const residence =  {
                ...getters.residenceSection
            };
            residence.items = [
                ...residence.items,
                {
                    name: "phone",
                    value: getters.basicInfo.phone,
                    canText: getters.basicInfo.accept_texts,
                },
                {
                    name: "alt_phone",
                    label: "Phone 2",
                    value: getters.basicInfo.alt_phone,
                    canText: getters.basicInfo.alt_accept_texts,
                },
            ]
            
        },
        deliverySection(_state, getters) {
            return {
                name: "deliverySection",
                title: "Device Delivery Address",
                route: "application.clientInfo.delivery",
                address: getters.deliveryAddress,
                items: [
                    {
                        name: "delivery_name",
                        value: getters.delivery.delivery_name,
                    },
                    {
                        name: "address_1",
                        label: "Address",
                        value: getters.deliveryAddress.address_1,
                    },
                    {
                        name: "address_2",
                        label: false,
                        value: getters.deliveryAddress.address_2,
                    },
                    {
                        name: "facility",
                        value: getters.deliveryAddress.facility,
                    },
                    {
                        name: "city",
                        value: getters.deliveryAddress.city,
                    },
                    {
                        name: "state",
                        value: getters.deliveryAddress.state,
                    },
                    {
                        name: "zip",
                        value: getters.deliveryAddress.zip,
                    },
                    {
                        name: "delivery_phone",
                        label: "Phone",
                        value: getters.delivery.delivery_phone,
                    },
                ],
            };
        },
        diagnosisSection(_state, getters) {
            return {
                name: "diagnosisSection",
                title: "Client Diagnosis",
                route: "application.clientInfo.diagnosis",
                items: [
                    {
                        name: "in_hospice",
                        value: getters.basicInfo.in_hospice,
                        boolToText: true,
                    },
                    {
                        name: "is_degenerative_diagnosis",
                        value: getters.basicInfo.is_degenerative_diagnosis,
                        boolToText: true,
                    },
                    {
                        name: "medical_diagnosis",
                        value: getters.basicInfo.medical_diagnosis,
                    },
                    {
                        name: "medical_diagnosis_date",
                        value: getters.medicalDate,
                    },
                    {
                        name: "speech_diagnosis",
                        value: getters.basicInfo.speech_diagnosis,
                    },
                    {
                        name: "speech_diagnosis_date",
                        value: getters.speechDate,
                    },
                    {
                        name: "additional_diagnosis",
                        value: getters.basicInfo.additional_diagnosis
                            ? getters.basicInfo.additional_diagnosis
                            : "--",
                    },
                    {
                        name: "additional_diagnosis_date",
                        value: getters.additionalMedicalDate,
                    },
                    {
                        name: "additional_diagnosis_2",
                        value: getters.basicInfo.additional_diagnosis_2
                            ? getters.basicInfo.additional_diagnosis_2
                            : "--",
                    },
                    {
                        name: "additional_diagnosis_2_date",
                        value: getters.additionalSpeechDate,
                    },
                    {
                        name: "result_of_accident",
                        value: getters.basicInfo.result_of_accident,
                        boolToText: true,
                    },
                    {
                        name: "accident_type",
                        value: getters.basicInfo.accident_type,
                    },
                    {
                        name: "accident_state",
                        value: getters.basicInfo.accident_state,
                    },
                    {
                        name: "date_of_accident",
                        value: getters.accidentDate,
                    },
                ],
            };
        },
        equipmentSection(_state, getters) {
            return {
                name: "equipmentSection",
                title: "Current Equipment",
                route: "application.clientInfo.equipment",
                items: [
                    {
                        name: "has_sgd",
                        label: "Has SGD",
                        value: getters.basicInfo.has_sgd,
                        boolToText: true,
                    },
                    {
                        name: "sgd_purchase_date",
                        value: getters.purchaseDate,
                    },
                    {
                        name: "sgd_has_warranty",
                        value: getters.basicInfo.sgd_has_warranty,
                        boolToText: true,
                    },
                    {
                        name: "current_equipment_comments",
                        label: "Equipment Comments",
                        value: getters.basicInfo.current_equipment_comments,
                    },
                    {
                        name: "has_mount",
                        value: getters.basicInfo.has_mount,
                        boolToText: true,
                    },
                    {
                        name: "mount_purchase_date",
                        value: getters.mountDate,
                    },
                    {
                        name: "mount_comments",
                        label: "Mount Comments",
                        value: getters.basicInfo.mount_comments,
                    },
                ],
            };
        },
        mdmSection(_state, getters) {
            return {
                name: "mdmSection",
                title: "Medical Decision Maker",
                route: "application.clientInfo.mdm",
                address: getters.advocateAddress,
                items: [
                    {
                        name: "advocate_relationship",
                        value: getters.advocate.advocate_relationship,
                    },
                    {
                        name: "advocate_first_name",
                        label: "First Name",
                        value: getters.advocate.advocate_first_name,
                    },
                    {
                        name: "advocate_last_name",
                        label: "Last Name",
                        value: getters.advocate.advocate_last_name,
                    },
                    {
                        name: "address_1",
                        label: "Address",
                        value: getters.advocateAddress.address_1,
                    },
                    {
                        name: "address_2",
                        label: false,
                        value: getters.advocateAddress.address_2,
                    },
                    {
                        name: "facility",
                        value: getters.advocateAddress.facility,
                    },
                    {
                        name: "city",
                        value: getters.advocateAddress.city,
                    },
                    {
                        name: "state",
                        value: getters.advocateAddress.state,
                    },
                    {
                        name: "zip",
                        value: getters.advocateAddress.zip,
                    },
                    {
                        name: "advocate_phone",
                        label: "Phone",
                        value: getters.advocate.advocate_phone,
                        canText: getters.advocate.advocate_phone_accept_texts,
                    },
                    {
                        name: "advocate_alt_phone",
                        label: "Phone 2",
                        value: getters.advocate.advocate_alt_phone,
                        canText: getters.advocate.advocate_alt_phone_accept_texts,
                    },
                    {
                        name: "advocate_fax",
                        label: "Fax",
                        value: getters.advocate.advocate_fax,
                    },
                    {
                        name: "advocate_email",
                        label: "Email",
                        value: getters.advocate.advocate_email,
                        keep_underscores: true
                    },
                    {
                        name: "advocate_preferred_language",
                        label: "Preferred Language",
                        value: getters.advocate.advocate_preferred_language,
                    },
                    {
                        name: "advocate_preferred_method_of_communication",
                        label: "Method of Communication",
                        value: getters.advocate
                            .advocate_preferred_method_of_communication,
                    },
                ],
            };
        },
        additionalMdmSection(_state, getters) {
            return {
                name: "additionalMdmSection",
                title: "Additional Medical Decision Maker",
                route: "application.clientInfo.mdm",
                address: getters.advocateAdditionalAddress,
                items: [
                    {
                        name: "advocate_relationship",
                        value: getters.advocate.additional_relationship,
                    },
                    {
                        name: "advocate_first_name",
                        label: "First Name",
                        value: getters.advocate.additional_first_name,
                    },
                    {
                        name: "advocate_last_name",
                        label: "Last Name",
                        value: getters.advocate.additional_last_name,
                    },
                    {
                        name: "address_1",
                        label: "Address",
                        value: getters.advocateAdditionalAddress.address_1,
                    },
                    {
                        name: "address_2",
                        label: false,
                        value: getters.advocateAdditionalAddress.address_2,
                    },
                    {
                        name: "city",
                        value: getters.advocateAdditionalAddress.city,
                    },
                    {
                        name: "state",
                        value: getters.advocateAdditionalAddress.state,
                    },
                    {
                        name: "zip",
                        value: getters.advocateAdditionalAddress.zip,
                    },
                    {
                        name: "advocate_phone",
                        label: "Phone",
                        value: getters.advocate.additional_phone,
                        canText: getters.advocate.additional_phone_accept_texts,
                    },
                    {
                        name: "advocate_email",
                        label: "Email",
                        value: getters.advocate.additional_email,
                        keep_underscores: true
                    },
                ],
            };
        },
        slpSection(_state, getters) {
            return {
                name: "slpSection",
                title: "SLP Info",
                route: "application.clientInfo.slp",
                address: getters.slpAddress,
                items: [
                    {
                        name: "slp_first_name",
                        value: getters.slp.slp_first_name,
                        label: "First name",
                    },
                    {
                        name: "slp_last_name",
                        label: "Last name",
                        value: getters.slp.slp_last_name,
                    },
                    {
                        name: "slp_credentials",
                        label: "Credentials",
                        value: getters.slp.slp_credentials,
                    },
                    {
                        name: "slp_asha_number",
                        label: "ASHA number",
                        value: getters.slp.slp_asha_number,
                    },
                    {
                        name: "slp_license_number",
                        label: "License",
                        value: getters.slp.slp_license_number,
                    },
                    {
                        name: "slp_email",
                        label: "Email",
                        value: getters.slp.slp_email,
                        keep_underscores: true
                    },
                    {
                        name: "slp_alt_email",
                        label: "Email 2",
                        value: getters.slp.slp_alt_email,
                        keep_underscores: true
                    },
                    {
                        name: "facility",
                        value: getters.slpAddress.facility,
                    },
                    {
                        name: "address_1",
                        label: "Address",
                        value: getters.slpAddress.address_1,
                    },
                    {
                        name: "address_2",
                        label: false,
                        value: getters.slpAddress.address_2,
                    },
                    {
                        name: "city",
                        value: getters.slpAddress.city,
                    },
                    {
                        name: "state",
                        value: getters.slpAddress.state,
                    },
                    {
                        name: "zip",
                        value: getters.slpAddress.zip,
                    },
                    {
                        name: "slp_phone",
                        label: "Phone",
                        value: getters.slp.slp_phone,
                        canText: getters.slp.slp_phone_accept_texts,
                    },
                    {
                        name: "slp_alt_phone",
                        label: "Phone 2",
                        value: getters.slp.slp_alt_phone,
                        canText: getters.slp.slp_alt_phone_accept_texts,
                    },
                ],
            };
        },
        physicianSection(_state, getters) {
            return {
                name: "physicianSection",
                title: "Prescribing Physician",
                route: "application.clientInfo.physician",
                address: getters.physicianAddress,
                items: [
                    {
                        name: "physician_first_name",
                        value: getters.physician.physician_first_name,
                        label: "First name",
                    },
                    {
                        name: "physician_last_name",
                        label: "Last name",
                        value: getters.physician.physician_last_name,
                    },
                    {
                        name: "physician_phone",
                        label: "Phone",
                        value: getters.physician.physician_phone,
                    },
                    {
                        name: "npi_number",
                        label: "NPI Number",
                        value: getters.physician.npi_number,
                    },
                    {
                        name: "physician_credential",
                        label: "Credentials",
                        value: getters.physician.physician_credential,
                    },
                    {
                        name: "physician_email",
                        label: "Email",
                        value: getters.physician.physician_email,
                        keep_underscores: true
                    },
                    {
                        name: "medicaid_provider_number",
                        label: "Medicaid Provider Number",
                        value: getters.physician.medicaid_provider_number,
                    },
                    {
                        name: "practice_name",
                        label: "Practice",
                        value: getters.physician.practice_name,
                    },
                    {
                        name: "address_1",
                        label: "Address",
                        value: getters.physicianAddress.address_1,
                    },
                    {
                        name: "address_2",
                        label: false,
                        value: getters.physicianAddress.address_2,
                    },
                    {
                        name: "city",
                        value: getters.physicianAddress.city,
                    },
                    {
                        name: "state",
                        value: getters.physicianAddress.state,
                    },
                    {
                        name: "zip",
                        value: getters.physicianAddress.zip,
                    },
                    {
                        name: "physician_fax",
                        label: "Fax",
                        value: getters.physician.physician_fax,
                    },
                ],
            };
        },
        insuranceSections(_state, getters, _rootState, rootGetters) {
            return getters.insurances.map((insurance) => {
                return {
                    name: insurance.insurance_type + "Section",
                    title: "Insurance - " + startCase(insurance.insurance_type),
                    route: `application.insuranceInfo.${insurance.insurance_type}Info`,
                    items: [
                        {
                            name: "insurance_id",
                            value: insurance.insurance_id,
                            label: "Insurance ID",
                        },
                        {
                            name: "insurance_name",
                            value: insurance.insurance_name,
                            label: "Insurance Name",
                        },
                        {
                            name: "policyholder_last_name",
                            value:
                                insurance.aob?.policy_first_name &&
                                insurance.aob?.policy_last_name
                                    ? `${insurance.aob?.policy_first_name} ${insurance.aob?.policy_last_name}`
                                    : null,
                            label: "Policy Holder",
                        },
                        {
                            name: "payer_level",
                            label: "Payer Level",
                            value: rootGetters["insuranceCompletion/payerLevel"](insurance.payer_level)
                        }
                    ],
                };
            });
        },
        trialV2DeviceSpecifications(_state, getters) {
            const equipmentSelection = getters.trialEquipmentv2?.equipment_selection;
            const all_specs = equipmentSelection?.specifications;
            const reqs = equipmentSelection?.requested_specifications;
            if (!all_specs || !reqs) return [];

            const response = [];

            reqs.forEach(req_section => {
                // First we find the specs section that matches the label of our current requirements section
                const spec_section = all_specs.find(spec_sec => spec_sec.section == req_section.label);
                // If we don't find a specs section, it means we have no data for this section so we skip the section
                if (!spec_section) return;
                // Once we have the req section and the specs section, we need the req to get the label, and the specs to get the value
                // We match them by joining the req.name with the key of the spec
                const specs = spec_section.fields;

                // Add a separator between the sections
                response.push({name: "", value: ""})
                
                req_section.fields.forEach(req => {
                    if (specs[req.name] && specs[req.name].length) {
                        response.push({
                            'name': req.label ?? req.name,
                            'value': specs[req.name]
                        })
                    }
                });
            });

            return response;
        },
        deviceSpecifications(_state, getters) {
            const all_specs = getters.selectedDevice?.specifications;
            const reqs = getters.selectedDevice?.requested_specifications;
            if (!all_specs || !reqs) return [];

            const response = [];

            reqs.forEach(req_section => {
                // First we find the specs section that matches the label of our current requirements section
                const spec_section = all_specs.find(spec_sec => spec_sec.section == req_section.label);
                // If we don't find a specs section, it means we have no data for this section so we skip the section
                if (!spec_section) return;
                // Once we have the req section and the specs section, we need the req to get the label, and the specs to get the value
                // We match them by joining the req.name with the key of the spec
                const specs = spec_section.fields;

                // Add a separator between the sections
                response.push({name: "", value: ""})
                
                req_section.fields.forEach(req => {
                    if (specs[req.name] && specs[req.name].length) {
                        response.push({
                            'name': req.label ?? req.name,
                            'value': specs[req.name]
                        })
                    }
                });
            });

            return response;
        },
        equipmentSelectionSection(_state, getters) {
            return {
                name: "equipmentSelectionSection",
                title: "Equipment Summary",
                route: "application.equipmentSelection",
                items: [
                    {
                        name: "selected_device",
                        value: getters.device.device,
                    },
                    {
                        name: "Equipment Selection Notes",
                        value: getters.equipmentSelection.equipment_summary,
                    },
                    {
                        name: "Has Custom Item",
                        value: getters.equipmentSelection.custom_item,
                        boolToText: true,
                    },
                    ...getters.deviceSpecifications
                ],
            };
        },
        trialEquipmentSelectionV2Section(_state, getters) {
            return {
                name: "trialEquipmentSelectionV2Section",
                title: "Trial Equipment Summary V2",
                route: "application.trialEquipmentSelectionV2",
                items: [
                    {
                        name: "selected_device",
                        value: getters.trialEquipmentv2?.equipment_selection?.device?.device,
                    },
                    {
                        name: "Equipment Selection Notes",
                        value: getters.trialEquipmentv2?.equipment_summary,
                    },
                    {
                        name: "Has Custom Item",
                        value: getters.trialEquipmentv2?.custom_item,
                        boolToText: true,
                    },
                    ...getters.trialV2DeviceSpecifications
                ],
            };
        },
        trialEquipmentSelectionSection(_state, getters) {
            return {
                name: "trialEquipmentSelectionSection",
                title: "Trial Equipment Summary",
                route: "application.trialEquipmentSelection",
                items: [
                    {
                        name: "selected_device",
                        value: getters.trialEquipment.selected_device,
                        class: "capitalize",
                    },
                    {
                        name: "vocabulary",
                        value: getters.trialEquipment.vocabulary,
                        class: "capitalize",
                    },
                    {
                        name: "insurance_eligibility",
                        value: getters.trialEquipment.insurance_eligibility,
                    },
                    {
                        name: "mount",
                        value: getters.trialEquipment.mount,
                    },
                    {
                        name: "switches",
                        value: getters.trialEquipment.switches,
                        class: "capitalize",
                    },    
                    {
                        name: "switch_mounts",
                        value: getters.trialEquipment.switch_mounts,
                        class: "capitalize",
                    },             
                    {
                        name: "touchguide",
                        value: getters.trialEquipment.touchguide,
                        class: "capitalize",
                    },
                    {
                        name: "keyguard",
                        value: getters.trialEquipment.keyguard,
                        class: "capitalize",
                    },
                    {
                        name: "access",
                        value: getters.trialEquipment.access,
                        class: "capitalize",
                    },
                    {
                        name: "bilingual",
                        value: getters.trialEquipment.bilingual,
                        boolToText: true,
                    },
                    {
                        name: "language",
                        value: getters.trialEquipment.language,
                    },
                    {
                        name: "wrap",
                        value: getters.trialEquipment.wrap,
                    },
                    {
                        name: "interested_app",
                        value: getters.trialEquipment.interested_app,
                        class: "capitalize",
                    },
                ],
            };
        },
        qadSection(_state, getters) {
            return {
                name: "qadSection",
                title: "System Integration",
                route: "application.admin.qad",
                items: [
                    {
                        name: "bt_sales_order_id",
                        value: getters.systemIntegration.bt_sales_order_id,
                        label: "BT Order Id",
                    },
                    {
                        name: "bt_patient_id",
                        value: getters.systemIntegration.bt_patient_id,
                        label: "BT Patient Id",
                    },
                    {
                        name: "ca_number",
                        value: getters.systemIntegration.ca_number,
                        label: "CA Number",
                    },
                    {
                        name: "qad_sales_order",
                        value: getters.soNumbers,
                        label: "QAD Sales Order",
                    },
                    {
                        name: "qad_rma_number",
                        value: getters.rmaNumbers,
                        label: "QAD RMA",
                    },
                    {
                        name: "qad_customer_id",
                        value: getters.systemIntegration.qad_customer_id,
                        label: "QAD Customer Id",
                    },
                    {
                        name: "outcome",
                        value: getters.systemIntegration.outcome,
                        label: "QAD Outcome",
                    },
                ],
            };
        },

    },
};
