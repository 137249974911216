export default [
    {
        path: "",
        name: "application.trialEquipmentSelectionV2",
        component: () => import("@/views/application/equipmentSelection/equipmentSelection.vue"),
        meta: {
            type: "trial"
        },
    },
    {
        path: "specifications/:section",
        name: "application.trialEquipmentSelectionV2.specifications",
        component: () => import("@/views/application/equipmentSelection/specifications.vue"),
        meta: {
            type: "trial"
        },
    },
];
