<template>
    <div class="grid gap-4 grid-cols-fit-250">
        <!-- comment box -->
        <quick-form
            v-if="allowComment"
            :schema="schema"
            @success="onSuccess"
            ref="form"
            @updated="onUpdated"
            class="col-span-full mb-3"
        />

        <!-- sections -->
        <review-section
            v-for="section in sections"
            :filterNull="filterNull"
            :key="section.title"
            :title="section.title"
            :route="section.route"
            :items="section.items"
            :address="section.address"
        />
    </div>
</template>

<script>

export default {
    components: {
    },

    data() {
        return {
            formFields: {},
            trial_lengths: [
                { value: "4 Weeks" },
                { value: "12 Weeks" },
                { value: "Legacy" },
                { value: "Legacy 4 Weeks" },
                { value: "Legacy 12 Weeks" },
                { value: "8 Week Exception" },
            ]
        }
    },
    props: {
        filterNull: {
            type: Boolean,
            default: true,
        },

        ignoreSections: {
            type: Array,
            default: () => [],
        },

        allowComment: {
            type: Boolean,
            default: false,
        },

        permittedSections: {
            type: Array,
            default: () => [],
        }
    },

    methods: {
        onUpdated({fields}){
            this.formFields = fields;
        },
        async onSuccess({ data }) {
            this.$store.dispatch("applications/setCurrentApplication", {
                application: data,
            });
            this.$refs.form.reset();
        },
    },

    computed: {
        application() {
            return this.$store.getters["applications/current"] ?? {};
        },

        ignoredSections() {
            let ignored = [...this.ignoreSections];
            let additionalIgnored = [];

            // if we don't have an additional MDM set, then don't include that section
            if(!this.application.client_info?.advocate?.has_additional_advocate){
                ignored.push("additionalMdmSection");
            }

            if(this.application.type.toLowerCase() === "trial"){
                additionalIgnored = [
                    "equipmentSelectionSection",
                ];
                if (!this.application.trial_equipment_selection_v2_info || this.application.trial_equipment_selection_v2_info.disabled) {
                    additionalIgnored.push("trialEquipmentSelectionV2Section");
                }
                if (!this.application.trial_equipment_selection_info || this.application.trial_equipment_selection_info?.disabled) {
                    additionalIgnored.push("trialEquipmentSelectionSection");
                }
            } else {
                additionalIgnored = [
                    "trialEquipmentSelectionSection",
                    "trialEquipmentSelectionV2Section"
                ];
            } 

            return [...ignored, ...additionalIgnored];
        },
        isTrial() {
            return this.application.type.toLowerCase() === "trial";
        },
        schema() {
            return {
                endpoint: `funding/application/${this.application.alias}/comment`,
                resetOnSuccess: false,
                buttonsClass: "!mt-0",
                fields: [
                    {
                        name: "internal_comment",
                        type: "textarea",
                        labelClass: "font-bold",
                        label: "Comments",
                        value: this.application.internal_comment,
                    },
                    {
                        name: "trial_length",
                        type: "select",
                        label: "Trial Length",
                        value: this.application.system_integration?.trial_length,
                        placeholder: "Select trial length",
                        options: this.trial_lengths,
                        hide: !this.isTrial,
                        shouldFilter: !this.isTrial
                    },
                    {
                        name: "exception_alert",
                        type: "element",
                        tag: "warning-alert",
                        label: false,
                        content: "You must provide a manager approved reason for the exception.",
                        hide: !this.isTrial || this.formFields.trial_length !== "8 Week Exception",
                    },
                    {
                        name: "trial_length_exception_reason",
                        label: "Document reason for the approved exception",
                        type: "textarea",
                        value: this.application.system_integration?.trial_length_exception_reason,
                        required: this.isTrial && this.formFields.trial_length == "8 Week Exception",
                        validate: ["hasLetter", "scaryChars"],
                        hide: !this.isTrial || this.formFields.trial_length !== "8 Week Exception",
                        shouldNull: !this.isTrial || this.formFields.trial_length !== "8 Week Exception",
                    },
                ],
            };
        },

        basicInfo() {
            return this.application.client_info?.basic_info ?? {};
        },

        sections() {
            return this.$store.getters["applicationSummary/getSections"](this.clientSections);
        },  
        clientSections() {
            if(this.permittedSections.length) return this.permittedSections;

            let sections = [
                "aboutSection",
                "residenceSection",
                "diagnosisSection",
                "deliverySection",
                "equipmentSection",
                "equipmentSelectionSection",
                "trialEquipmentSelectionSection",
                "trialEquipmentSelectionV2Section",
                "mdmSection",
                "additionalMdmSection",
                "slpSection",
                "physicianSection",
                "qadSection",
                "insuranceSections"
            ]
            // filter ignored sections
            sections = sections.filter(section => !this.ignoredSections.includes(section));


            console.log("Rendiering sections", sections);
            return sections;
        },
    },
};
</script>
<style scoped></style>
