export default [
    {
        path: "",
        name: "application.equipmentSelection",
        component: () => import("@/views/application/equipmentSelection/equipmentSelection.vue"),
        meta: {
            type: "purchase"
        },
    },
    {
        path: "specifications/:section",
        name: "application.equipmentSelection.specifications",
        component: () => import("@/views/application/equipmentSelection/specifications.vue"),
        meta: {
            type: "purchase"
        },
    },
];
