<template>
    <option v-bind="computedAttributes" v-if="!this.option.hide">
        {{ text }}
    </option>
</template>

<script>
import startCase from "lodash/startCase";
import isObject from "@/services/utils/isObject.js";

export default {
    props: ["option"],

    computed: {
        text() {
            return this.option.text ?? startCase(this.option.value);
        },
        computedAttributes() {
            let attributes = {
                // set our option value
                value: this.option.value,
            };

            // merge in any attributes from our field.attributes property
            if (isObject(this.option.attributes)) {
                attributes = { ...attributes, ...this.option.attributes };
            }

            return attributes;
        },
    },
};
</script>
