<template>
    <button 
        :title="`Select all states in the ${region} region`"
        class="rounded py-1 px-2 transition-all"
        :class="computedClasses" 
        @click="onClick"
    >
        {{ region }}
    </button>
</template>
<script>
//import isEqual from "lodash/isEqual";

export default {
    emits: ["selectRegion", "deselectRegion"],

    data() {
        return {
        };
    },
    
    props: {
        region: {
            type: String,
            required: true,
        },
        selection: {
            default: [],
        },
        regionNames: {
            default: [],
        },
        setTheme: {
            type: String,
            default: 'default',
        },
    },

    computed: {
        isSelected(){
            return this.regionNames.every(region => this.selection.includes(region));
            //return isEqual(this.selection, this.regionNames);
        },

        computedClasses(){
            if(this.setTheme === "dark"){
                return [ 
                    "text-accentBlue",
                    "hover:bg-[#004887]", 
                    this.isSelected ? "bg-[#004887]" : "bg-blueGray-900/60", 
                ];
            }

            return [
                "hover:bg-[#9bd0ff]", 
                this.isSelected ? "bg-[#9bd0ff]" : "bg-gray-100",
            ];
        },
    },

    methods: {
        onClick(){
            if(this.isSelected){
                return this.$emit('deselectRegion', this.region);
            } 

            this.$emit('selectRegion', this.region);
        }
    },
};
</script>
<style scoped>
</style>
