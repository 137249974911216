import Errors from "./FormErrorService";
import Validator from "./FormValidationService";
import { reactive } from "vue";
import hasValue from "./FieldHasValue";
import isObject from "@/services/utils/isObject.js";
import router from "@/router";
import { isString } from "lodash";

/**
 *
 *  The Form class handles form data and submission
 *
 * @typedef {Object} Schema
 * @property {string} [endpoint] the endpoint to submit this form to, if none is provided the form will simply validate and return the data without hitting the API
 * @property {string} [method=post] the method to use for the http request
 * @property {object} [headers] an object containing any additional headers to send with the http request
 * @property {string} [submitText=submit] text to use for the submit button 
 * @property {boolean} [notifications=true] show response notifications
 * @property {object} [attributes] Any attributes to add to the main div element of this form
 * @property {Field[]} fields an array of field objects used to build this form's fields
 * 
 * @typedef {Object} Field
 * @property {string} name the name of the form field (the only required property)
 * @property {string} [type=text] used to set the element and type (group, textarea, select, or any inputs type are valid).
 * @property {string|int} [value] the starting value of the form field
 * @property {boolean} [hide] used to dynamically hide or show the field
 * @property {string} [label=field.name] the text to use in the field's label, defaults to the field's name
 * @property {string} [description] additional text to place under the form label
 * @property {boolean} [required] fields default to being optional, add this property to make them required
 * @property {object} [wrapperAttributes] any attributes you wish to apply to the outermost wrapper for this fieldset
 * @property {object} [attributes] key/value pairs of any additional attributes you wish to add to the field element
 * @property {string|object} [teleport] Add a teleport target within this field grouping, allows you to place arbitrary elements
                   
 * @property {array} [validate] the validation rules to apply to this field.
 *  The field validation rules must be an array consisting of either of the following:
 *
 *  You may use any of the pre-defined rules as defined in ./FormValidationRules by passing the names of
 *  any rules you wish to apply as array elements eg. ["alpha", "minLength:3"]
 *
 *  Alternatively you may also pass a callback function in the validate array so long as that function follows the
 *  requirements for a rule as defined in ./FormValidationRules:
 *  ["alpha", "minLength:3", ({value}) => {return +value > 10 ? true : "Must be greater than 10" }]
 *
 * 
 * @returns {Promise|object} Returns the following:
 * @property {object} inputs the name:value pairs for each input in the form  
 * @property {object|null} data if this form was submitted to an API returns the response data, otherwise null
 * @property {int|null} status if this form was submitted to an API returns the response status code, otherwise null
 */

const ignoredFieldTypes = ['teleport', 'upload', 'element'];
export default class Form {
    /**
     * Create a new Form instance.
     * @param {object} $http - our http instance
     * @param {object} schema - the schema of our form
     */
    constructor($http, schema, emitInitUpdate) {
        this._$http = $http;

        // the function from the parent form to call when we init our form data
        this._emitInitUpdate = emitInitUpdate;

        // save our schema
        this._schema = schema;

        // init our fields object
        this.data = reactive({});

        // have we submitted the form successfully?
        this._success = null;

        // do we have any headers to add to the form?
        this._headers = schema.headers ?? {};

        // by default we don't have a file field, but this may change during initializeFields
        this.hasFile = false;

        // initialize our field data
        this.initializeFields();

        // build our errors class
        this.errors = new Errors();

        this.validator = new Validator(this._schema, this.data, this.errors);

        this.emptySoftRequiredFields = [];
    }

    /**
     * Set our schema
     */
    setSchema(schema) {
        this._schema = schema;
        this.validator.schema = schema;
    }

    /************************************
     * 
     * Initialize Fields
     * 
     ***********************************/

    /**
     *  Use our _schema.fields to populate our form fields
     */
    initializeFields() {
        Object.keys(this.data).forEach((key) => delete this.data[key]);
        this.initializeGroup(this._schema.fields);
        this._emitInitUpdate(this.data);
    }

    /**
     * Initialize a group of form fields
     *
     * @param {array} fields
     */
    initializeGroup(fields) {
        // for each field specified in our data populate our internal record
        for (const field of fields) {

            // some field types are just placeholders for other components, ignore those.
            if (ignoredFieldTypes.includes(field.type)) {
                continue;
            }

            // If we are passed a group, recursively call the initialize group method
            if (field.type === "group") {
                this.initializeGroup(field.fields);
                continue;
            }

            // check for a missing name attribute
            if (typeof field.name !== "string") {
                return console.error(`Missing form field name ${field}`);
            }

            // otherwise check for duplicate field name in our data
            if (Object.keys(this.data).includes(field.name)) {
                return console.error(`Duplicate form field name ${field.name}`);
            }

            // if all is clear set this field's initial value
            this.setFieldInitialValue(field);
        }
    }

    /**
     * Set the initial value of our field
     *
     * @param {object} field
     */
    setFieldInitialValue(field) {
        let value = field.value ?? "";

        if (field.type === "radio") {
            value = this.setRadioValue(field);
        }

        if (field.type === "select") {
            value = this.setSelectValue(field);
        }

        if (field.type === "checkbox") {
            value = this.setCheckboxValue(field);
        }

        if (field.type === "checkboxes") {
            value = this.setCheckboxesValue(field);
        }

        if (field.type === "radios") {
            value = this.setRadiosValue(field);
        }

        if (field.type === "counts") {
            value = this.setCountsValue(field);
        }

        if (field.type === "file") {
            this.hasFile = true;
        }

        this.data[field.name] = value;
    }

    /**
     * Set the initial value of a radio button set
     *
     * @param {object} field
     * @returns {mixed}
     */
    setRadioValue(field) {

        // if we set a value on our field, use that
        if (field.value !== null && field.value !== undefined) {

            // if we have a field that doesn't need to verify its values, return it as is
            if(field.verifyOptions === false){
                return field.value;
            }
            
            // we need to filter out any values that don't match our options, in case we remove/update an option
            // intentionally using == here instead of === to allow for type coercion
            return field.options.find(option => option.value == field.value) ? field.value : null;
        }

        // otherwise check an option for the checked property
        const selectedOption = field.options.find((option) => option.checked);
        if (selectedOption) {
            return selectedOption?.value;
        }

        // finally if no initial value was set, return null
        return null;
    }

    /**
     * Set the initial value of a select option
     *
     * @param {object} field
     * @returns {mixed}
     */
    setSelectValue(field) {
        // if we set a value on our field, use that
        if (field.value !== null && field.value !== undefined) {

            // if we have a field that doesn't need to verify its values, return it as is
            if(field.verifyOptions === false){
                return field.value;
            }

            // we need to filter out any values that don't match our options, in case we remove/update an option
            // intentionally using == here instead of === to allow for type coercion
            return field.options.find(option => option.value == field.value) ? field.value : null;
        }

        // otherwise return null
        return null;
    }

    /**
     * Set the initial value of a checkboxes set
     *
     * @param {object} field
     * @returns {array}
     */
    setCheckboxesValue(field) {
        // if we set a value on our field, use that
        if (Array.isArray(field.value)) {

            // if we have a field that doesn't need to verify its values, return it as is
            if(field.verifyOptions === false){
                return field.value;
            }

            // we need to filter out any values that don't match our options, in case we remove/update an option
            // intentionally using == here instead of === to allow for type coercion
            return field.value.filter(value => field.options.find(option => option.value == value));
        }

        // find our selected options and build an array of their values
        return field.options
            .filter((option) => option.checked)
            .map((option) => option.value);
    }


    /**
     * Set the initial value of a radios set
     *
     * @param {object} field
     * @returns {array}
     */
    setRadiosValue(field) {
        // if we set a value on our field, use that
        if (isObject(field.value)) {
            return field.value;
        }

        const valueObject = {};

        // TODO allow default values
        field.inputs.forEach(input => valueObject[input.name] = null);

        // find our selected options and build an array of their values
        return valueObject;
    }

    /**
     * Set the initial value of a counts set
     *
     * @param {object} field
     * @returns {array}
     */
    setCountsValue(field) {
        // first build an object with the counts options as keys and 0 as the value
        const valueObject = {};
        let usedOptionValues = [];

        // TODO allow default values
        field.options.forEach(option => {
            if(usedOptionValues.includes(option.value)){
                console.error(`Duplicate counts option value ${option.value}`);
                return;
            }

            if(!isString(option.value)){
                console.error(`Counts option value is not a string: ${option.value}`);
                return;
            }

            valueObject[option.value] = 0;
            usedOptionValues.push(option.value);
        });

        // Once we have our values object build with all counts set to 0, we can update 
        // any values that were saved in our field.value
        if (isObject(field.value)) {
            for(const key in field.value){
                if (key in valueObject || field.verifyOptions === false) {
                    valueObject[key] = Number(field.value[key]);
                }
            }
        }

        return valueObject;
    }

    /**
     * Set the initial value of a checkbox element
     *
     * @param {object} field
     * @returns
     */
    setCheckboxValue(field) {
        let value = field.value ?? 1;
        return field.checked ? value : null;
    }

    /************************************
     * 
     * Prepare form data
     * 
     ***********************************/


    /**
     * Return all relevant data for a  form.
     *
     * @return {formData}
     */
    prepareFormData() {
        const formData = new FormData();

        this._headers["Content-Type"] = "multipart/form-data";

        // prepare our method
        this.prepareFormMethod(formData);

        // prepare our form data fields
        this.prepareFieldGroup(this._schema.fields, formData);

        // prepare our shared fields
        this.prepareSharedFields(formData);

        // return our formData object
        return formData;
    }

    prepareSharedFields(formData) {
        if(this._schema.noSharedFields){
            return;
        }

        // add current route to our formData
        if(!formData.has("_route")){
            formData.append("_route", router.currentRoute?.value?.name);
        }
    }

    /**
     * Prepare our formData method
     *
     * @param {object} formData
     */
    prepareFormMethod(formData) {
        // save our form method
        let method = this._schema.method ?? "post";
        method = method.toLowerCase();

        // set our method manually if its not the default "post" method
        if (method !== "post") {
            formData.append("_method", method);
        }
    }

    prepareFieldGroup(group, formData, groupNull = false) {
        // for each property in our original data object build an appropriate property in our
        // newly minted formData
        for (const field of group) {

            // some field types are just placeholders for other components, ignore those.
            if (ignoredFieldTypes.includes(field.type)) {
                continue;
            }

            // recursively call this method if we have a group (and it isn't set to be filtered)
            if (field.type === "group" && !field.shouldFilter) {
                let shouldGroupNull = groupNull || field.shouldNull;
                this.prepareFieldGroup(field.fields, formData, shouldGroupNull);
                continue;
            }

            // ignore any fields that we want to filter from the final results
            if (field.shouldFilter) {
                continue;
            }

            // if this is a file field appended the appropriate data
            if (field.type === "file") {
                formData.append(field.name, this.data[field.name]);
                continue;
            }

            // get our field value, if we have shouldNull set to true null that value
            let value = (field.shouldNull || groupNull) ? null : this.data[field.name];


            // convert our value to make sure null & booleans work as intended
            value = this.formatPrimitives(value);

            // if we have a checkboxes field, lets filter out any selected
            // options with the shouldFilter property
            if (field.type === "checkboxes") {
                value = this.filterOptions(field.options, value);
            }

            // if we have a unchecked checkbox field ignore it NOTE: this doesn't
            // appear to do anything, as unchecked checkboxes seem to have a value
            // of null not an empty string or false (unless maybe we manually set 
            // the value to one of those types)
            if (field.type === "checkbox" && !field.asBoolean && (value === '' || value === false)) {
                continue;
            }

            // if we have a unchecked checkbox field set to asBoolean set its value to 0
            if (field.type === "checkbox" && field.asBoolean && !value && !field.shouldNull) {
                value = 0;
            }

            // if our value is an array make sure to append the data properly
            if (Array.isArray(value) || isObject(value)) {
                this.appendDataStructure(formData, field.name, value);
                continue;
            }

            // check if this softRequired field is empty
            if(field.softRequired && !hasValue(value, field)){
                this.emptySoftRequiredFields.push(field.name);
            }

            // otherwise just set the appropriate property on our formData
            formData.set(field.name, value);
        }
    }

    /**
     * convert our value to make sure null & booleans work as intended
     * 
     * @param {mixed} value 
     * @returns 
     */
    formatPrimitives(value){
        // formData.set coerces null to the string 'null', and an empty string '' to null. 
        // Why? I don't know, I don't make the rules, I'm just forced to live with them. 
        // So if we have a null value that we want to send to the server we must first 
        // replace that null with ''
        if (value === null) {
            return '';
        }

        // likewise the booleans get converted to strings, and laravel can convert string "1" 
        // and "0" to their boolean values, but not "true" and "false", so lets coerce those
        if (value === true) {
            return 1;
        }

        if (value === false) {
            return 0;
        }

        return value;
    }

    /**
     * We need to recursively append our arrays and objects to our formData object
     * 
     * @param {FormData} formData - our root formdata object 
     * @param {string} name - the name of the field
     * @param {mixed} value - the value of the field, property, or array entry 
     * @param {string} path - the accumulated path of array keys to this point (used in nested structures) 
     * @returns 
     */
    appendDataStructure(formData, name, value, path = ''){
        // if our array or object is empty set our form data value to null (an empty string)
        if ((Array.isArray(value) && !value.length) || (isObject(value) && !Object.keys(value).length)) {
            formData.append(`${name}${path}`, '');
            return;
        }

        for(const key in value){
            // get the value for this iteration 
            let subValue = value[key];

            // build the bracket notation path for this iteration so we can eventually append the full path 
            // when we finally reach a non-object/array value like so: "name[grandparent][parent][key]"
            let subPath = path + `[${key}]`;
            
            if(isObject(subValue) || Array.isArray(subValue)){
                this.appendDataStructure(formData, name, subValue, subPath);
                continue;
            }

            formData.append(`${name}${subPath}`, this.formatPrimitives(subValue));
        }
    }

    /**
     * Given an array of options objects, filter the values array to remove any
     * options with the property shouldFilter
     *
     * @param {array} options
     * @param {array} values
     * @returns
     */
    filterOptions(options, values) {
        options.forEach((option) => {
            // if our option has the shouldFilter value set and that option is
            // included in our field value, remove it
            if (option.shouldFilter && values.includes(option.value)) {
                values = values.filter((item) => item !== option.value);
            }
        });

        return values;
    }


    /************************************
     * 
     * Other Methods
     * 
     ***********************************/


    /**
     * update the value of a field
     *
     * @param {string} name
     * @param {mixed} value
     */
    updateValue(name, value) {
        // if we provide an array as the fist param recursively call this function on each
        // element in the array
        if (Array.isArray(name)) {
            name.forEach((item) => {
                this.updateValue(item.name, item.value);
            });
            return;
        }

        if (!Object.keys(this.data).includes(name)) {
            return console.error("Form field does not exist in data");
        }

        this.data[name] = value;
        this.errors.clear(name);
    }

    setFieldError(field, message){
        this.errors.set(field, message);
    }

    /**
     * Reset the form fields.
     */
    reset() {
        // use our _schema to repopulate our form to its defaults
        this.initializeFields();

        // clear our errors
        this.errors.clearAll();
    }

    /**
     * Return our headers in a wrapping object
     *
     * @returns {object}
     */
    getConfig() {
        let config = {
            headers: this._headers,
            notifications: this._schema.notifications ?? true,
        };

        // merge in any config options
        if (isObject(this._schema.config)) {
            config = { ...config, ...this._schema.config };
        }

        return config;
    }

    /**
     * Submit the form.
     */
    submit() {
        this._success = null;

        // validate our fields
        this.validateAll();

        // submit our form
        return new Promise((resolve, reject) => {
            // create a deep copy of the input data
            const results = {
                inputs: JSON.parse(JSON.stringify(this.data)),
            };

            // validate form and abort if validation fails
            if (this.errors.any()) {
                results.data = {
                    errors: `form failed validation: ${this.errors.allAsString()}`,
                };
                results.status = 422;
                return reject(results);
            }

            // if we didn't set an endpoint, simply return our inputs:values data as a results object
            if (!this._schema.endpoint) {
                results.data = null;
                results.status = 200;
                this.onSuccess();
                return resolve(results);
            }

            // reset our soft required fields
            this.emptySoftRequiredFields = [];

            // format our form data
            const data = this.prepareFormData();
            const config = this.getConfig();

            // set our empty soft required fields
            results.emptySoftRequiredFields = this.emptySoftRequiredFields;
            if(this.emptySoftRequiredFields?.length){
                config.successNotification = false;
            }

            // post to our API
            this._$http
                .post(this._schema.endpoint, data, config)
                .then((response) => {
                    // perform success actions
                    this.onSuccess();

                    // return formatted results
                    results.data = response?.data;
                    results.status = response?.status;
                    return resolve(results);
                })
                .catch((error) => {
                    // perform error actions
                    this.onFail(error);

                    // return formatted errors
                    if (error?.response) {
                        results.data = error?.response?.data;
                        results.status = error?.response?.status;
                    }
                    return reject(results);
                });
        });
    }

    /**
     * Validate all of our fields
     */
    validateAll() {
        this.validator.all();
        return !this.errors.any();
    }

    /**
     * Validate a given field
     *
     * @param {string} name
     */
    validateField(name) {
        this.validator.validateField(name);
    }

    /**
     * Handle a successful form submission.
     */
    onSuccess() {
        this._success = true;
        if (this._schema.resetOnSuccess !== false) {
            this.reset();
        }
    }

    /**
     * Handle a failed form submission.
     *
     * @param {object} error - our http error response
     */
    onFail(error) {
        // if this isn't a validation error, then abort
        if (error?.response?.status !== 422) {
            return;
        }

        // record our errors to our error object from our responseMacro validationErrors response
        if (error?.response?.data?.errors?.validationErrors) {
            this.errors.record(error.response?.data.errors.validationErrors);
            return;
        }

        // record our errors to our error object from a default laravel 422 response
        if (error?.response?.data?.errors) {
            this.errors.record(error.response?.data.errors);
        }
    }
}
